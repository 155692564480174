window.requestAnimationFrame = (function () {
  return (
    window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function (callback) {
      window.setTimeout(callback, 1000 / 60);
    }
  );
})();

(function ($, window, document) {
  // -----------------------------------
  // Window 相關設定
  var w = window,
    win = $(window),
    ww,
    wh,
    ws;

  // 取得Window設定值
  var windowSetting = function () {
    ww = win.innerWidth();
    wh = win.innerHeight();
    ws = win.scrollTop();
  };
  windowSetting();

  // -----------------------------------
  //go top
  const goTop = $('#gotop');

  goTop.on('click touchstart', function () {
    $('html, body').animate(
      {
        scrollTop: 0,
      },
      400,
      'swing'
    );
  });

  // -----------------------------------
  // Loader
  function loaderRemove() {
    setTimeout(function () {
      $('.loader').addClass('close');
    }, 400);
  };

  $(document).on('click', '.menu', function () {
    if ($(this).hasClass('click') === true) {
      $(this).removeClass('click');
      $('.menu_botton').removeClass('click');
    } else {
      $(this).addClass('click');
      $('.menu_botton').addClass('click');
    }
  });

  // ----------------------------------- Window 相關監測
  // window on scroll use javascript
  // Reference: https://stackoverflow.com/a/10915048
  // http://dev.w3.org/2006/webapi/DOM-Level-3-Events/html/DOM3-Events.html#event-type-scroll
  function onScrollEventHandler(ev) {}
  function onResizeEventHandler(ev) {
    windowSetting();
  }
  function onLoadEventHandler(ev) {
    loaderRemove();
  }

  if (w.addEventListener) {
    w.addEventListener('scroll', onScrollEventHandler, false);
    w.addEventListener('resize', onResizeEventHandler, false);
    w.addEventListener('load', onLoadEventHandler, false);
  } else if (w.attachEvent) {
    w.attachEvent('onscroll', onScrollEventHandler);
    w.attachEvent('onresize', onScrollEventHandler);
    w.attachEvent('load', onLoadEventHandler);
  }
})(jQuery, window, document);
